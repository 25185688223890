.tab-evaluation-view {
  .nav-tab {
    margin-bottom: 0;
    border-bottom: 1px solid #dedfe1;
    // display: inline-flex;

    li {
      margin-bottom: -1px;
    }
  }

  &.shorter {
    .nav-tab {
      display: inline-flex;
    }
  }

  .tab-content {
    margin-top: 28px;
  }
}

.table-simple {
  tr {
    &:first-child {
      td {
        &:first-child {
          border-top-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
        }
      }
    }

    &:last-child {
      &:first-child {
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        border-bottom-right-radius: 4px;
      }
    }

    &:not(:last-child) {
      td,
      th {
        border-bottom: 1px solid $color-blue-100;
      }
    }

    &.active {
      position: relative;

      td {
        position: relative;
      }

      &::after {
        display: block;
        content: '';
        position: absolute;
        pointer-events: none;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 4px solid $color-blue-300;
        border-radius: 4px;
      }
    }
  }
}

.table-group-column {
  thead {
    tr {
      &:first-child {
        td,
        th {
          border-bottom: 1px solid $color-blue-100;
        }

        td {
          &:first-child {
            border-top-left-radius: 4px;
          }

          &:last-child {
            border-top-right-radius: 4px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      &:not(:last-child) {
        td,
        th {
          border-bottom: 1px solid $color-blue-100;
        }
      }

      &.active {
        position: relative;
        // box-shadow: inset 0px 0px 0px 4px #6599ED;
        td {
          position: relative;
        }

        // td {
        //   border-top-width: 4px;
        //   border-bottom-width: 4px;
        //   border-color: $color-blue-300;
        //   &:first-child {
        //     border-left-width: 4px;
        //     border-color: $color-blue-300;
        //     border-top-left-radius: 4px;
        //     border-bottom-left-radius: 4px;
        //   }
        //   &:last-child {
        //     border-right-width: 4px;
        //     border-color: $color-blue-300;
        //     border-top-right-radius: 4px;
        //     border-bottom-right-radius: 4px;
        //   }
        // }
        &::after {
          display: block;
          content: '';
          pointer-events: none;
          position: absolute;
          top: -1px;
          left: -1px;
          width: calc(100% + 2px);
          height: calc(100% + 2px);
          border: 4px solid $color-blue-300;
          border-radius: 4px;
        }
      }
    }
  }
}

.eval_form {
  div {
    &.active {
      &::after {
        display: block;
        content: '';
        pointer-events: none;
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 4px solid $color-blue-300;
        border-radius: 4px;
      }
    }
  }
}
