$white: #fff;
$color-blue-600: #3974D3;
$color-blue-500: #3f7fe8;
$color-blue-400: #6599ED;
$color-blue-300: #7EA9F0;
$color-blue-100: #C3D7F8;
$color-blue-50: #ECF2FD;

$color-blue-01: #4a86e0;
$color-blue-02: #cadfff;

$color-gray-02: #eaeaea;
$color-gray-03: #c5c5c5;
$color-gray-06: #313030;

$color-gray-400: #4B515B;

$color-green-01: #08AA5C;
$color-green-02: #DDF8EB;

$color-yellow-01: #FFC11D;
$color-yellow-02: #FFFBE6;

$color-primary-02: #FFE4E4;
$color-red: #FF0000;
// $border-color_1: #3f7fe8;

// Override css variables ToastMessage
:root {
  // --toastify-color-light: #fff;
  // --toastify-color-dark: #121212;
  // --toastify-color-info: #3498db;
  // --toastify-color-success: #07bc0c;
  // --toastify-color-warning: #f1c40f;
  // --toastify-color-error: #e74c3c;
  // --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  // --toastify-icon-color-info: var(--toastify-color-info);
  // --toastify-icon-color-success: var(--toastify-color-success);
  // --toastify-icon-color-warning: var(--toastify-color-warning);
  // --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 395px;
  // --toastify-toast-background: #fff;
  // --toastify-toast-min-height: 64px;
  // --toastify-toast-max-height: 800px;
  --toastify-font-family: "Noto Sans", sans-serif;
  // --toastify-z-index: 9999;

  --toastify-text-color-light: #313030;
  // --toastify-text-color-dark: #fff;

  // //Used only for colored theme
  // --toastify-text-color-info: #fff;
  // --toastify-text-color-success: #fff;
  // --toastify-text-color-warning: #fff;
  // --toastify-text-color-error: #fff;

  // --toastify-spinner-color: #616161;
  // --toastify-spinner-color-empty-area: #e0e0e0;

  // // Used when no type is provided
  // // toast("**hello**")
  // --toastify-color-progress-light: linear-gradient(
  //   to right,
  //   #4cd964,
  //   #5ac8fa,
  //   #007aff,
  //   #34aadc,
  //   #5856d6,
  //   #ff2d55
  // );
  // // Used when no type is provided
  // --toastify-color-progress-dark: #bb86fc;
  // --toastify-color-progress-info: var(--toastify-color-info);
  // --toastify-color-progress-success: var(--toastify-color-success);
  // --toastify-color-progress-warning: var(--toastify-color-warning);
  // --toastify-color-progress-error: var(--toastify-color-error);

  // // used to control the opacity of the progress trail
  // --toastify-color-progress-bgo: 0.2;
}


$grey-1: #EBEBED;