[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;

  &.simplebar-dragging {
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }

  &.simplebar-dragging {
    .simplebar-content {
      pointer-events: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none
    }

    .simplebar-track {
      pointer-events: all
    }
  }
}

.simplebar {
  &-wrapper {
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit
  }

  &-mask {
    direction: inherit;
    position: absolute;
    overflow: hidden;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
    z-index: 0
  }

  &-offset {
    direction: inherit !important;
    box-sizing: inherit !important;
    resize: none !important;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch
  }

  &-content-wrapper {
    direction: inherit;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    height: 100%;
    width: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0
    }
  }

  &-hide-scrollbar {
    ::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0
    }
  }

  &-content {

    &::after,
    &:before {
      content: ' ';
      display: table
    }
  }

  &-placeholder {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    pointer-events: none
  }

  &-height-auto-observer-wrapper {
    box-sizing: inherit !important;
    height: 100%;
    width: 100%;
    max-width: 1px;
    position: relative;
    float: left;
    max-height: 1px;
    overflow: hidden;
    z-index: -1;
    padding: 0;
    margin: 0;
    pointer-events: none;
    flex-grow: inherit;
    flex-shrink: 0;
    flex-basis: 0
  }

  &-height-auto-observer {
    box-sizing: inherit;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 1000%;
    width: 1000%;
    min-height: 1px;
    min-width: 1px;
    overflow: hidden;
    pointer-events: none;
    z-index: -1
  }

  &-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden
  }

  &-scrollbar {
    position: absolute;
    left: 0;
    right: 0;
    min-height: 10px;

    &:before {
      position: absolute;
      content: '';
      background: #757474;
      border-radius: 7px;
      opacity: 0;
      transition: opacity .2s .5s linear;
      top: 2px;
      bottom: 2px;
      left: 2px;
      right: 2px
    }

    &.simplebar-visible:before {
      opacity: .5;
      transition-delay: 0s;
      transition-duration: 0s
    }
  }

  &-track {
    &.simplebar-vertical {
      top: 0;
      width: 6px
    }

    &.simplebar-horizontal {
      left: 0;
      height: 11px;

      .simplebar-scrollbar {
        right: auto;
        left: 0;
        top: 0;
        bottom: 0;
        min-height: 0;
        min-width: 6px;
        width: auto
      }
    }
  }

  &-dummy-scrollbar-size {
    direction: rtl;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 500px;
    width: 500px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: scrollbar !important
  }

  &-dummy-scrollbar-size>div {
    width: 200%;
    height: 200%;
    margin: 10px 0
  }

  &-hide-scrollbar {
    position: fixed;
    left: 0;
    visibility: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none
  }
}

[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0
}