// Override existing css classes
.Toastify__toast-container {
	padding: 0;
}

// /** Used to define the position of the ToastContainer **/
// .Toastify__toast-container--top-left {
// }
// .Toastify__toast-container--top-center {
// }
// .Toastify__toast-container--top-right {
// }
// .Toastify__toast-container--bottom-left {
// }
// .Toastify__toast-container--bottom-center {
// }
// .Toastify__toast-container--bottom-right {
// }

// /** Classes for the displayed toast **/
.Toastify__toast {
	box-shadow: 0 4px 15px 4px rgba(22, 20, 20, 0.08);
	padding: 12px 16px;
	border: 1px solid transparent;
	font-size: 14px;
	line-height: 20px;
	&.Toastify__toast--success {
		border-color: $color-green-01;
		background-color: $color-green-02;
	}
	&.Toastify__toast--info {
		border-color: $color-blue-01;
		background-color: $color-blue-02;
	}
	&.Toastify__toast--warning {
		border-color: $color-yellow-01;
		background-color: $color-yellow-02;
	}
	&.Toastify__toast--error {
		border-color: $color-red;
		background-color: $color-primary-02;
	}
}
// .Toastify__toast--rtl {
// }
.Toastify__toast-body {
	padding: 0;
	align-items: flex-start;
}

.Toastify__toast-icon {
	padding-top: 4px;
	width: 16px;
}

// /** handle the notification color and the text color based on the theme **/
// .Toastify__toast-theme--dark {
// }
// .Toastify__toast-theme--light {
// }
// .Toastify__toast-theme--colored.Toastify__toast--default {
// }
// .Toastify__toast-theme--colored.Toastify__toast--info {
// }
// .Toastify__toast-theme--colored.Toastify__toast--success {
// }
// .Toastify__toast-theme--colored.Toastify__toast--warning {
// }
// .Toastify__toast-theme--colored.Toastify__toast--error {
// }

// .Toastify__progress-bar {
// }
// .Toastify__progress-bar--rtl {
// }
// .Toastify__progress-bar-theme--light {
// }
// .Toastify__progress-bar-theme--dark {
// }
// .Toastify__progress-bar--info {
// }
// .Toastify__progress-bar--success {
// }
// .Toastify__progress-bar--warning {
// }
// .Toastify__progress-bar--error {
// }
// /** colored notifications share the same progress bar color **/
// .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
// .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
// .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
// .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
// }

// /** Classes for the close button. Better use your own closeButton **/
// .Toastify__close-button {
// }
// .Toastify__close-button--default {
// }
// .Toastify__close-button > svg {
// }
// .Toastify__close-button:hover,
// .Toastify__close-button:focus {
// }