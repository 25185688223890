@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@import "_variables.scss";
// @import "_checkbox.scss";
@import "_toastmessage.scss";
@import "_datepicker.scss";
@import "_evaluation-view.scss";
@import "_simplebar.scss";