.cpickerdropdown.ant-picker-dropdown {
  font-family: 'Noto Sans', sans-serif;
  .ant-picker-cell .ant-picker-cell-inner {
    min-width: 32px;
    line-height: 32px;
    height: 32px;
  }
  .ant-picker-header {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ant-picker-header-view {
    font-size: 16px;
  }
  .ant-picker-ranges {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  & > li {
    line-height: 36px;
  }
  .ant-picker-now {
    color: $color-blue-01;
    font-weight: 600;
  }
  .ant-picker-ok button {
    font-weight: 600;
    background-color: $color-blue-400;
    color: $white;
    border-radius: 8px;
    padding: 8px 19px;
    height: 36px;
  }
  .ant-picker-datetime-panel-container {
    .ant-picker-ranges {
      justify-content: flex-end;
      .ant-picker-ok {
        margin-inline-start: 16px;
      }
    }
  }
  .ant-picker-panel-container {
    padding-top: 28px;
    position: relative;
  }
}

.cpickerdtimeropdown.ant-picker-dropdown {
  .ant-picker-time-panel .ant-picker-content {
    gap: 24px;
    padding: 0 8px;
    height: 364px;
  }
  .ant-picker-time-panel-column {
    &:not(:first-child) {
      border-inline-start-width: 0;
    }
    & > li.ant-picker-time-panel-cell {
      margin-inline: 0;
      .ant-picker-time-panel-cell-inner {
        width: 56px;
        height: 32px;
        line-height: 32px;
        border-radius: 2px;
        padding-inline-start: 0;
        text-align: center;
        :hover {
          background-color: $color-gray-02;
        }
      }
    }
    & > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
      background-color: $color-blue-400;
      color: $white;
    }
  }
  .ant-picker-ranges {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  & > li {
    line-height: 36px;
  }
  .ant-picker-now {
    color: $color-blue-01;
    font-weight: 600;
    .ant-picker-now-btn {
      padding: 4px 8px;
    }
  }
  .ant-picker-ok button {
    font-weight: 600;
    background-color: $color-blue-400;
    color: $white;
    border-radius: 8px;
    padding: 8px 19px;
    height: 36px;
  }
}
.picker-tabs {
  border-bottom: 1px solid $color-gray-02;
  background-color: $white;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 28px;
  button {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 20px;
    height: 28px;
    color: $color-gray-06;
    transition: all 0.15s ease-in-out;
    flex: 1;
    &.active {
      color: $white;
      background-color: $color-blue-400;
    }
    &:first-child {
      border-top-left-radius: 6px;
    }
    &:last-child {
      border-top-right-radius: 6px;
    }
  }
}
.picker-large-custom {
  &.ant-picker-large {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.ant-picker-outlined {
  border-color: rgb(197 197 197) !important;
  box-shadow: none !important;
  &:hover {
    border-color: rgb(101 153 237) !important;
  }
  &:focus-within {
    border-color:  rgb(45 90 165) !important;
  }
}
.ant-picker-small {
  padding: 6px 7px !important;
}
.ant-picker-middle {
  height: 48px;
}